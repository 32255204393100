import axios from '@/../axios-auth'
import store from '../index'

const state = {
  perfilSelected: null,

  // MODULOS
  avaibleModules: [
    // COLOCAR NO MÓDULO ADMINISTRATIVO
    {
      label: 'Controle de Acesso',
      value: 'acl',
      permissions: [
        {
          label: 'Página de controle de acesso',
          key: {
            subject: 'ACL',
            action: 'read',
          },
        },
      ],
    },
    {
      label: 'Fechamento',
      value: 'fechamento',
      permissions: [
        {
          label: 'Página de fechamento',
          key: {
            subject: 'fechamento',
            action: 'read',
          },
        },
      ],
    },
    {
      label: 'Direcionador',
      value: 'direcionador',
      permissions: [
        {
          label: 'Página do direcionador',
          key: {
            subject: 'direcionador',
            action: 'read',
          },
        },
      ] },
    {
      label: 'Finaceiro',
      value: 'financeiro',
      permissions: [
        {
          label: 'Página do financeriro',
          key: {
            subject: 'financeiro',
            action: 'read',
          },
        },
      ] },
    {
      label: 'Auditor',
      value: 'auditor',
      permissions: [
        {
          label: 'Página do auditor',
          key: {
            subject: 'auditor',
            action: 'read',
          },
        },
      ] },
    {
      label: 'Orçamento',
      value: 'orcamento',
      permissions: [
        {
          label: 'Página de orçamento',
          key: {
            subject: 'orcamento',
            action: 'read',
          },
        },
      ] },
    {
      label: 'Preventivo',
      value: 'preventivo',
      permissions: [
        {
          label: 'Página do preventivo',
          key: {
            subject: 'preventivo',
            action: 'read',
          },
        },
      ] },
    {
      label: 'Ordem de Serviço',
      value: 'os',
      permissions: [
        {
          label: 'Página de OS',
          key: {
            subject: 'os',
            action: 'read',
          },
        },
        {
          label: 'Cadastrar OS',
          key: {
            subject: 'os',
            action: 'store',
          },
        },
        {
          label: 'Totalizadores de atendimento',
          key: {
            subject: 'os',
            action: 'count',
          },
        },
      ] },
    {
      label: 'Relatórios',
      value: 'relatorios',
      permissions: [
        {
          label: 'Relatorio de atendente',
          key: {
            subject: 'relatorio.atendente',
            action: 'read',
          },
        },
      ] },
    {
      label: 'Empresas',
      value: 'empresa',
      permissions: [
        {
          label: 'Página de Empresas',
          key: {
            subject: 'empresa',
            action: 'read',
          },
        },
        {
          label: 'Visualizar dados da empresa',
          key: {
            subject: 'empresa/view',
            action: 'read',
          },
        },
        {
          label: 'Visualizar dados seguradora',
          key: {
            subject: 'master/seguradora',
            action: 'read',
          },
        },
      ] },
    {
      label: 'Seguradora',
      value: 'seguradora',
      permissions: [
        {
          label: 'Página de orçamento Da seguradora',
          key: {
            subject: 'seguradora/orcamento',
            action: 'read',
          },
        },
        {
          label: 'Relatório OS Da seguradora',
          key: {
            subject: 'seguradora.relatorio.os',
            action: 'read',
          },
        },
      ],
    },
    {
      label: 'Administrativo',
      value: 'adm',
      permissions: [
        {
          label: 'Cadastros',
          key: {
            subject: 'adm/cadastro',
            action: 'read',
          },
        },
      ] },
    {
      label: 'Técnico',
      value: 'tecnico',
      permissions: [
        {
          label: 'Página do técnico',
          key: {
            subject: 'tecnico',
            action: 'read',
          },
        },
      ] },
  ],

  // EDITAR PERFIL
  modulesSelected: null,
  permissionsSelected: {},

  checkValidate: {},
}
const mutations = {
  SET_PERFIL_SELECTED(estado, data) {
    if (data.permissoes) {
      if (data.permissoes !== '[]') {
        estado.permissionsSelected = JSON.parse(data.permissoes)
      }
    }
    estado.perfilSelected = data
  },

  // eslint-disable-next-line consistent-return
  SET_MODULE(estado, data) {
    if (!data) {
      estado.modulesSelected = data

      return null
    }

    if (!estado.checkValidate[data.value]) estado.checkValidate[data.value] = {}

    data.permissions.forEach((permission, idx) => {
      if (!estado.checkValidate[data.value][idx]) estado.checkValidate[data.value][idx] = false
      if (estado.permissionsSelected[data.value]) {
        const checkIfHavePermission = estado.permissionsSelected[data.value].filter(e => e.action === permission.key.action && e.subject === permission.key.subject)
        if (checkIfHavePermission.length > 0) estado.checkValidate[data.value][idx] = true
      }
    })

    estado.modulesSelected = data
  },

  CLEAR_EDIT_INFO(estado, data) {
    data.forEach(item => {
      if (estado[item.key]) {
        estado[item.key] = item.value
      }
    })
  },

  CHANGE_PERFIL_NAME(estado, name) {
    if (estado.perfilSelected) {
      estado.perfilSelected.name = name
    }
  },

  SET_PERMISSIONS(estado, { permission, status }) {
    if (status) {
      if (!estado.permissionsSelected[permission.value]) {
        estado.permissionsSelected[permission.value] = []
      }
      estado.permissionsSelected[permission.value].push({ ...permission.key })
    } else {
      estado.permissionsSelected[permission.value] = estado.permissionsSelected[permission.value].filter(e => e.action !== permission.key.action && e.subject !== permission.key.subject) || []
    }

    estado.checkValidate[permission.value][permission.id] = status
  },
}
const actions = {
  clearEditModal({ commit }) {
    const ARRAY_KEYS = [
      {
        key: 'perfilSelected',
        value: null,
      },
      {
        key: 'modulesSelected',
        value: null,
      },
      {
        key: 'permissionsSelected',
        value: null,
      },
      {
        key: 'checkValidate',
        value: {},
      },
    ]

    commit('CLEAR_EDIT_INFO', ARRAY_KEYS)
  },

  // eslint-disable-next-line no-unused-vars
  async saveAcl({ commit }) {
    const { perfilSelected, permissionsSelected } = store.state.acl

    if (!perfilSelected.perfilId) {
      const empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id

      const { data } = await axios.post('api/perfil/store/', {
        nome: perfilSelected.name,
        empresa_id: empresaID,
        permissoes: permissionsSelected,
      })
      return data
    }

    const { data } = await axios.put(`api/perfil/update/${perfilSelected.perfilId}`, {
      nome: perfilSelected.name,
      permissoes: permissionsSelected,
    })

    return data
  },

  async getUserPermission() {
    // const {} = await axios.get('api/perfil/')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
