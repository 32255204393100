import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
})

instance.defaults.headers.common.Authorization = `Bearer ${localStorage.token}`
instance.defaults.headers.common.Accept = 'application/json'

instance.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    localStorage.clear()
  }
  return Promise.reject(error)
})

export default instance
