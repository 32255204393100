import store from '../../store'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedI: true,
    },
    beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
        next({ name: 'home' })
      } else {
        next()
      }
    },
  },
  {
    path: '/sair',
    name: 'sair',
    // component: () => import('@/views/Login.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedI: true,
    },
    beforeEnter() {
      localStorage.clear()
      store.dispatch('auth/logout')
    },
  },
  {
    path: '/seguradora',
    name: 'seguradora',
    component: () => import('@/views/master/SeguradoraHome.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/linha',
    name: 'linha',
    component: () => import('@/views/master/LinhaHome.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/marca',
    name: 'marca',
    component: () => import('@/views/master/MarcaHome.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/tecnicos',
    name: 'tecnicos',
    component: () => import('@/views/master/TecnicosHome.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/revenda',
    name: 'revenda',
    component: () => import('@/views/master/RevendaHome.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/anexar/arquivos/:id',
    name: 'anexar.arquivos',
    component: () => import('@/views/AnexarArquivos.vue'),
    meta: {
      layout: 'full',
      resource: 'anexos/arquivos',
      action: 'read',
    },
    props: route => ({ id: route.params.id, hash: route.hash }),
  },
  {
    path: '/assinar/:id',
    name: 'assinar',
    component: () => import('@/views/Assinatura.vue'),
    meta: {
      layout: 'full',
      resource: 'assinar',
      action: 'read',
    },
    props: route => ({ id: route.params.id, hash: route.hash }),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/empresas',
    name: 'empresas',
    component: () => import('../../views/empresas/EmpresaHome.vue'),
    meta: {
      action: 'read',
      resource: 'empresa',
    },
  },
  {
    path: '/os',
    name: 'os',
    component: () => import('../../views/direcionador/dashboard/dashDirecionador.vue'),
    meta: {
      action: 'read',
      resource: 'os',
    },
  },
  {
    path: '/fechamento',
    name: 'fechamento',
    component: () => import('../../views/fechamento/Fechamento.vue'),
    meta: {
      action: 'read',
      resource: 'fechamento',
    },
  },
  {
    path: '/empresa/view/:id',
    name: 'empresa-view',
    component: () => import('../../views/empresas/EmpresaView.vue'),
    meta: {
      action: 'read',
      resource: 'empresa/view',
    },
  },
  {
    path: '/seguradora/orcamento',
    name: 'seguradora-orcamento',
    component: () => import('../../views/seguradora/orcamento/Orcamento.vue'),
    meta: {
      action: 'read',
      resource: 'seguradora/orcamento',
    },
  },
  {
    path: '/seguradora/os/view/:id',
    name: 'seguradora-os-view',
    component: () => import('@/views/seguradora/os-view/OsView.vue'),
    meta: {
      action: 'read',
      resource: 'seguradora/orcamento',
    },
  },
  {
    path: '/dashboard',
    name: 'home',
    component: () => import('../../views/dashboard/GeneralDash.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
    },
  },
  {
    path: '/auditor',
    name: 'auditor',
    component: () => import('../../views/auditor/dashboard/DashAuditor.vue'),
    meta: {
      action: 'read',
      resource: 'auditor',
    },
  },
  {
    path: '/orcamento',
    name: 'orcamento',
    component: () => import('../../views/orcamento/dashboard/DashOrcamento.vue'),
    meta: {
      resource: 'orcamento',
      action: 'read',
    },
  },
  {
    path: '/direcionador',
    name: 'direcionador',
    component: () => import('../../views/direcionador/dashboard/dashDirecionador.vue'),
    meta: {
      resource: 'direcionador',
      action: 'read',
    },
  },
  {
    path: '/financeiro',
    name: 'financeiro',
    component: () => import('../../views/financeiro/dashboard/DashFinanceiro.vue'),
    meta: {
      resource: 'financeiro',
      action: 'read',
    },
  },
  {
    path: '/preventivo',
    name: 'preventivo',
    component: () => import('../../views/preventivo/dashboard/DashPreventivo.vue'),
    meta: {
      resource: 'preventivo',
      action: 'read',
    },
  },
  {
    path: '/administrador/cadastro',
    name: 'administrador.cadastro',
    component: () => import('../../views/administrador/cadastro/CadastroHome.vue'),
    meta: {
      resource: 'adm/cadastro',
      action: 'read',
    },
  },
  {
    path: '/ordem_de_servico/cadastro',
    name: 'ordemservico.cadastro',
    component: () => import('../../views/OrdemServico/cadastro/CadastroOSHome.vue'),
    meta: {
      action: 'store',
      resource: 'os',
    },
  },
  {
    path: '/relatorio/atendente',
    name: 'relatorio.atendente',
    component: () => import('../../views/table/vue-good-table/GoodTable.vue'),
    meta: {
      action: 'read',
      resource: 'relatorio.atendente',
    },
  },
  {
    path: '/master/seguradora',
    name: 'master.seguradora',
    component: () => import('../../views/master/SeguradoraHome.vue'),
    meta: {
      action: 'read',
      resource: 'master.seguradora',
    },
  },
  {
    path: '/relatorio/seguradora/os',
    name: 'relatorio.seguradora.os',
    component: () => import('../../views/seguradora/relatorios/relatorio-os/RelatorioOs.vue'),
    meta: {
      action: 'read',
      resource: 'seguradora.relatorio.os',
    },
  },
  {
    path: '/os/view/:id',
    name: 'os-view',
    component: () => import('@/views/OrdemServico/os-view/OSView.vue'),
    meta: {
      action: 'read',
      resource: 'os',
    },
  },
  {
    path: '/tecnico',
    name: 'view.tecnico',
    component: () => import('@/views/administrador/cadastro/tabs/Tecnicos.vue'),
    meta: {
      action: 'read',
      resource: 'tecnico',
    },
  },
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/ACL/AccessControl.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/misc/notAuthorized.vue'),
    meta: {
      resource: 'dashboard',
    },
  },
]

export default routes
