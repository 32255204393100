const state = {
  selectedMenu: {},
}
const mutations = {
  SET_SELECTED_MENU(stateObj, menu) {
    stateObj.selectedMenu = menu
  },
}
const actions = {
  setSelectedMenu({ commit }, menu) {
    commit('SET_SELECTED_MENU', menu)
  },
}
export default {
  state,
  mutations,
  actions,
  getters: {
    selectedMenu: localState => localState.selectedMenu,
  },
}
