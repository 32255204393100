/* eslint-disable no-return-await */
// eslint-disable-next-line no-unused-vars
import store from '..'
import axios from '../../../axios-auth'

const state = {
  items: [],

  loadingData: false,

  filteredList: [],

  statusClick: null,

  statisticsItems: [
    {
      icon: 'DollarSignIcon',
      color: 'light-success',
      title: '',
      subtitle: 'CMR',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'DollarSignIcon',
      color: 'light-warning',
      title: '',
      subtitle: 'CMG',
      customClass: 'mb-2 mb-sm-0',
    },
    {
      icon: 'CalendarIcon',
      color: 'light-primary',
      title: ' Dias',
      subtitle: 'TAT',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'RefreshCcwIcon',
      color: 'light-info',
      title: '%',
      subtitle: 'PT',
      customClass: '',
    },
    {
      icon: 'RefreshCcwIcon',
      color: 'light-info',
      title: '%',
      subtitle: 'REP',
      customClass: '',
    },
    {
      icon: 'RepeatIcon',
      color: 'light-info',
      title: '%',
      subtitle: 'REIN',
      customClass: '',
    },
    {
      icon: 'AlertTriangleIcon',
      color: 'light-danger',
      title: '%',
      subtitle: 'REC',
      customClass: '',
    },
    {
      icon: 'CornerUpLeftIcon',
      color: 'light-info',
      title: '0 %',
      subtitle: 'LMI',
      customClass: '',
    },
  ],
}
const mutations = {
  SET_ITEMS(stateObj, items) {
    stateObj.items = items
  },

  SET_FILTERED_LIST(stateObj, filteredList) {
    stateObj.filteredList = filteredList
  },

  SET_LOADING_DATA(stateObj, value) {
    stateObj.loadingData = value
  },

  SET_STATUS_CLICK(stateObj, status) {
    stateObj.statusClick = status
  },
}

const getters = {
  totalizadores(stateObj) {
    // eslint-disable-next-line no-unused-vars
    const { filteredList } = stateObj

    const obj = {
      total: 0,
      agendar: 0,
      em_atendimento: 0,
      aguardando_aprovacao: 0,
      em_reparo: 0,
      reparo_concluido: 0,
      reparo_entregue: 0,
      negado: 0,
      cancelado: 0,
      troca: 0,
      sem_contato: 0,
      revisao: 0,
      laudo: 0,
      total_atrasada: 0,
    }

    filteredList.forEach(item => {
      if (item.status) {
        switch (item.status) {
          case 'AGENDAR':
            obj.agendar += 1
            break
          case 'EM ATENDIMENTO':
            obj.em_atendimento += 1

            break
          case 'AGUARDANDO APROVAÇÃO':
            obj.aguardando_aprovacao += 1

            break
          case 'EM REPARO':
            obj.em_reparo += 1

            break
          case 'REPARO CONCLUÍDO':
            obj.reparo_concluido += 1

            break
          case 'REPARO ENTREGUE':
            obj.reparo_entregue += 1

            break
          case 'NEGADO':
            obj.negado += 1

            break
          case 'CANCELADO':
            obj.cancelado += 1

            break
          case 'TROCADO':
            obj.troca += 1

            break
          case 'SEM CONTATO':
            obj.sem_contato += 1

            break
          case 'REVISÃO':
            obj.revisao += 1

            break
          case 'LAUDO':
            obj.laudo += 1

            break
          default:
            break
        }
      }

      if (item.dias_em_aberto > 30) {
        if (!item.data_fechamento) {
          obj.total_atrasada += 1
        }
      }

      obj.total += 1
    })

    return [obj]
  },

  indicatorsData(stateObj) {
    const { filteredList, statisticsItems } = stateObj

    let qtOSFinalizada = 0
    let somaQDA = 0

    let valorTotalSeguradora = 0
    let qtS6 = 0

    let qtS9 = 0
    let valorTotalSeguradora9 = 0

    let qtReclamacao = 0
    let somaIS = 0

    // [reparabilidade, troca, reincidência]
    const reparoEntregueArray = [0, 0, 0]

    filteredList.forEach(o => {
      if (
        o.status_os_id === 6 ||
        // eslint-disable-next-line operator-linebreak
        o.status_os_id === 7 ||
        o.status_os_id === 9
      ) {
        qtOSFinalizada += 1
        somaQDA += o.dias_em_aberto

        if (o.produto_valor) {
          somaIS += parseFloat(o.produto_valor)
        }
      }

      if (o.status_os_id === 6) {
        qtS6 += 1
        reparoEntregueArray[0] += 1

        if (o.valor_total_seguradora) {
          valorTotalSeguradora += parseFloat(o.valor_total_seguradora)
        }
      }

      if (o.status_os_id === 9) {
        reparoEntregueArray[1] += 1

        qtS9 += 1
        if (o.valor_total_seguradora) {
          valorTotalSeguradora9 += parseFloat(o.valor_total_seguradora)
        }
      }

      if (o.status_os_id === 13) {
        reparoEntregueArray[2] += 1
      }

      if (o.reclamacao === 1) {
        qtReclamacao += 1
      }
    })

    const tCMG = parseInt(qtS6) + parseInt(qtS9)
    const vCMG =
      parseFloat(valorTotalSeguradora) + parseFloat(valorTotalSeguradora9)

    const calcReparoEntregue = () => {
      const array = reparoEntregueArray
      const sum = array.reduce((a, b) => a + b, 0)
      if (sum === 0) {
        return [0, 0, 0]
      }
      // eslint-disable-next-line no-mixed-operators
      const percentual = array.map(value => ((value * 100) / sum).toFixed(2))

      return percentual
    }
    const [REP, PT, REIN] = calcReparoEntregue()

    const CMR = (valorTotalSeguradora / qtS6 || 0).toFixed(2)
    const TAT = (somaQDA / qtOSFinalizada || 0).toFixed(0)
    const CMG = (vCMG / tCMG || 0).toFixed(2)
    const REC = ((qtReclamacao / filteredList.length) * 100 || 0).toFixed(2)

    const LMI = ((CMG / somaIS) * 100 || 0).toFixed(2)

    statisticsItems[0].title = `R$ ${Number.isNaN(CMR) ? 0 : CMR}`
    statisticsItems[1].title = `R$ ${Number.isNaN() ? 0 : CMG}`
    statisticsItems[2].title = `${Number.isNaN() ? 0 : TAT} Dias`
    statisticsItems[3].title = `${Number.isNaN() ? 0 : PT}%`
    statisticsItems[4].title = `${Number.isNaN() ? 0 : REP}%`
    statisticsItems[5].title = `${Number.isNaN() ? 0 : REIN}%`
    statisticsItems[6].title = `${Number.isNaN() ? 0 : REC}%`
    statisticsItems[7].title = `${Number.isNaN() ? 0 : LMI}%`
    return [...statisticsItems]
  },
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  getTotalizadoresOs({ commit }, { id, query, segId, atId }) {
    if (!query) {
      const getPreviousDate = (daysBefore, current = new Date()) => {
        const dateBefore = new Date(current.getTime())
        dateBefore.setDate(current.getDate() - daysBefore)
        // const brazilianDate = new Intl.DateTimeFormat('pt-BR', { year: 'numeric', day: '2-digit', month: '2-digit' }).format(dateBefore)

        const startDate = dateBefore.toISOString().slice(0, 10)
        const endDate = current.toISOString().slice(0, 10)

        return {
          startDate,
          endDate,
          label: `Últimos ${daysBefore} dias`,
        }
      }
      const previousDate = getPreviousDate(50)
      query = {
        data_inicial: previousDate.startDate,
        data_final: previousDate.endDate,
      }
    }
    commit('SET_LOADING_DATA', true)
    if (segId) {
      return new Promise(resolve => {
        axios
          .get(`api/ordem_servico/lista/seguradora/${segId}`, { params: query })
          .then(async res => {
            const items = await Promise.all(
              res.data.dados.map(async item => {
                const statusAgedamento = store
                  .dispatch('direcionador/verificarData', {
                    dAgendamento: item.data_agendamento,
                    dPrevisao: item.previsao_reparo,
                  })
                  .then(result => result)
                item.agendamento_status = await statusAgedamento
                return item
              }),
            )
            commit('SET_ITEMS', items)
            commit('SET_LOADING_DATA', false)
            resolve(items)
          })
      })
    }
    return new Promise(resolve => {
      axios
        .get(
          atId
            ? `api/ordem_servico/listar/atendente/${atId}`
            : `api/ordem_servico/lista/${id}`,
          { params: query },
        )
        .then(async res => {
          const items = await Promise.all(
            res.data.dados.map(async item => {
              const statusAgedamento = store.dispatch(
                'direcionador/verificarData',
                {
                  dAgendamento: item.data_agendamento,
                  dPrevisao: item.previsao_reparo,
                },
              )
              item.agendamento_status = await statusAgedamento
              return item
            }),
          )
          commit('SET_ITEMS', items)
          commit('SET_LOADING_DATA', false)
          resolve(items)
        })
    })
  },

  // eslint-disable-next-line no-shadow
  verificarData(state, { dAgendamento, dPrevisao }) {
    let data = dAgendamento
    const previsao = dPrevisao

    if (previsao) {
      data = previsao
    }
    if (!data) {
      return 'Não Agendado'
    }
    const dataAtual = new Date()
    let dia = dataAtual.getDate()
    let mes = dataAtual.getMonth() + 1
    const ano = dataAtual.getFullYear()

    if (mes < 10) {
      mes = `0${mes}`
    }
    if (dia < 10) {
      dia = `0${dia}`
    }
    const d1 = new Date(`${ano}-${mes}-${dia}`).getTime()
    const d2 = new Date(data).getTime()

    let status = ''

    if (!previsao && d2 < d1) {
      status = 'Desatualizado'
    } else if (d1 === d2) {
      status = 'Hoje'
    } else if (d2 > d1) {
      status = 'Próximos dias'
    } else {
      status = 'Realizado'
    }

    return status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
