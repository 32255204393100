import store from '..'
import axiosGlobal from '../../../axios-auth'
// eslint-disable-next-line import/no-cycle
import router from '../../router/index'

export default {
  namespaced: true,
  state: {
    idToken: null,
    userId: null,
    user: null,
    displayName: null,
    userRoles: null,
  },
  getters: {
    user(state) {
      return state.user
    },
    isAuthenticated(state) {
      return state.idToken !== null
    },
  },
  mutations: {
    authUser(state, userData) {
      state.idToken = userData.token
      state.userId = userData.userId
      state.displayName = userData.userName
    },
    setRoles(state, dados) {
      state.userRoles = dados
    },
    storeUser(state, user) {
      state.user = user
    },
    clearAuthData(state) {
      state.idToken = null
      state.userId = null
    },
  },
  actions: {
    async login({ commit }, authData) {
      await axiosGlobal.post('/api/login', {
        cpf_cnpj: authData.cpf_cnpj,
        password: authData.password,
      })
        // eslint-disable-next-line consistent-return
        .then(async res => {
          commit('authUser', {
            token: res.data.token,
            userId: res.data.user.id,
            userName: res.data.user.name,
          })
          const now = new Date()
          const expirationDate = now.getTime() + 3600 * 1000
          localStorage.setItem('token', res.data.token)
          localStorage.setItem('userId', res.data.user.id)
          localStorage.setItem('userName', res.data.user.name)
          localStorage.setItem('cpf_cnpj', res.data.user.cpf_cnpj)
          localStorage.setItem('userEmail', res.data.user.email)
          localStorage.setItem('expirationDate', expirationDate)
          localStorage.setItem('userData', JSON.stringify(res.data.user))
          localStorage.setItem('atualizacaoId', 1)

          router.go()
        })
    },
    async logout() {
      localStorage.clear()
      router.replace('/')
    },
    tryAutoLogin() {
      const { atualizacaoId } = localStorage

      if (!atualizacaoId) {
        store.dispatch('auth/logout')
      }
    },
  },
}
