import axios from '@/../axios-auth'

export const orcamentoState = {
  orcamentos: [],
}

export const orcamentoMutations = {
  SET_ORCAMENTOS(state, data) {
    state.orcamentos = data
  },
}

export const orcamentoActions = {
  async getOrcamentos({ commit }) {
    const userData = JSON.parse(localStorage.userData)
    const { seguradora_id: segId } = userData

    if (segId) {
      const { data } = await axios.get(`api/orcamento/listar/seguradora/${segId}`)

      if (data.dados) commit('SET_ORCAMENTOS', data.dados)
    }
  },
}
