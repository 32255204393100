import axios from '@/../axios-auth'
import store from '../index'

import { orcamentoActions, orcamentoState, orcamentoMutations } from './orcamento'

const stateData = {
  ...orcamentoState,

  filtros: {
    abertura: {
      dataInicial: null,
      dataFinal: null,
    },
  },

  abertura: {
    dataInicial: '',
    dataFinal: '',
  },

  revenda: {
    selected: [],
    options: [],
  },
  marca: {
    selected: [],
    options: [],
  },
  linha: {
    selected: [],
    options: [],
  },
  tipoAtendimento: {
    selected: [],
    options: [
      'NORMAL',
      'PRIORITÁRIO',
      'EMERGENCIAL',
    ],
  },
  statusAtendimento: {
    selected: [],
    options: [],
  },
  regiao: {
    selected: [],
    options: [],
  },
  estado: {
    selected: [],
    options: [],
  },
  cidade: {
    selected: [],
    options: [],
  },

  items: null,

  orcamentos: [],
  filteredOrcamentos: [],
}

const mutations = {
  ...orcamentoMutations,
  SET_ITEMS(state, data) {
    state.items = data
  },

  SET_ABERTURA_FILTRO(state, data) {
    state.abertura[data.stage] = data.value
  },

  SET_FILTROS(state, { data }) {
    const filtros = {}
    data.forEach(item => {
      filtros[item.name] = item.filtro
    })

    state.filtros = filtros
  },

  RESET_FILTER(state) {
    state.filtros = {
      abertura: {
        dataInicial: null,
        dataFinal: null,
      },
    }
  },

  SET_FILTERED_ORCAMENTOS(state, data) {
    state.filteredOrcamentos = data
  },

  SET_REVENDA(state, { options, selected }) {
    if (options) state.revenda.options = options
    if (selected) state.revenda.selected = selected
  },

  SET_FILTRO_OPTIONS(state, { name, options }) {
    state[name].options = options
  },

  SET_FILTRO_STATE(state, { name, data }) {
    state[name].selected = data
  },
}
const getters = {
  mapDataset(state) {
    const { items } = state
    const dataset = []

    // eslint-disable-next-line consistent-return
    items.forEach(os => {
      if (!os.latitude || !os.longitude) return null
      dataset.push({
        nome: os.cidade_nome,
        latitude: os.latitude,
        longitude: os.longitude,
      })
    })
    return dataset
  },

  indicadores(state) {
    const { items } = state

    if (!items) return null

    const qtOs = items.length
    let valorTotalSeguradora6 = 0
    let valorTotalSeguradora9 = 0
    let qtS6 = 0
    let qtS9 = 0
    let qtOSFinalizada = 0
    let somaQDA = 0
    let qtReclamacao = 0
    let somaIS = 0
    items.map(o => {
      if (o.reclamacao === 1) {
        qtReclamacao += 1
      }
      if (o.status_os_id === 6 || o.status_os_id === 7 || o.status_os_id === 9) {
        qtOSFinalizada += 1
        somaQDA += o.dias_em_aberto
        if (o.produto_valor) {
          somaIS += parseFloat(o.produto_valor)
        }
      }
      if (o.status_os_id === 6) {
        qtS6 += 1
        if (o.valor_total_seguradora) {
          valorTotalSeguradora6 += parseFloat(o.valor_total_seguradora)
        }
      }
      if (o.status_os_id === 9) {
        qtS9 += 1
        if (o.valor_total_seguradora) {
          valorTotalSeguradora9 += parseFloat(o.valor_total_seguradora)
        }
      }
    })
    const vCMG = parseFloat(valorTotalSeguradora6) + parseFloat(valorTotalSeguradora9)
    const tCMG = parseInt(qtS6) + parseInt(qtS9)
    const CMR = valorTotalSeguradora6 / qtS6
    const CMG = vCMG / tCMG
    const TAT = somaQDA / qtOSFinalizada
    const PT1 = (qtS9 / tCMG)
    const PT = PT1 * 100
    const REC = (qtReclamacao / qtOs) * 100
    const LMI = (CMG / somaIS) * 100

    return {
      qtOs,
      CMR,
      CMG,
      TAT,
      PT,
      REC,
      LMI,
    }
  },

  orcamentoStatistics(state) {
    const { filteredOrcamentos } = state

    let analise = 0
    let aprovado = 0
    let negado = 0

    filteredOrcamentos.forEach(item => {
      if (item.status.includes('APROVADO')) {
        aprovado += 1
      }
      if (item.status.includes('NEGADO')) {
        negado += 1
      }
      if (item.status.includes('AGUARDANDO ANÁLISE')) {
        analise += 1
      }
    })

    return { analise, aprovado, negado }
  },
}

const actions = {
  ...orcamentoActions,

  // eslint-disable-next-line no-unused-vars
  async getData({ commit }) {
    const userData = JSON.parse(localStorage.userData)
    const { seguradora_id: segId } = userData

    if (segId) {
      const getFormatedData = date => {
        const offset = date.getTimezoneOffset()
        const yourDate = new Date(date.getTime() - (offset * 60 * 1000))
        return yourDate.toISOString().split('T')[0]
      }

      let { filtros } = store.state.seguradora
      let dataInicialBody = null
      let dataFinalBody = null

      if (Object.keys(filtros).length === 1 && filtros.abertura) {
        const { dataInicial, dataFinal } = filtros.abertura

        if (!dataInicial || !dataFinal) {
          !dataInicial ? dataInicialBody = getFormatedData(new Date(new Date().setDate(new Date().getDate() - 30))) : null
          !dataFinal ? dataFinalBody = getFormatedData(new Date()) : null

          commit('SET_FILTROS', {
            data: [{
              name: 'abertura',
              filtro: {
                data_inicial: `${dataInicialBody} 00:00:00`,
                data_final: `${dataFinalBody} 23:59:59`,
              },
            }],
          })
        }
      }

      filtros = store.state.seguradora.filtros

      const { data } = await axios.post('api/ordem_servico/filtrar/seguradora', {
        seguradora_id: segId,
        filtros,
      })

      commit('SET_ITEMS', data.dados)
    }
  },

  async getFiltroOptions({ commit }) {
    // const userData = JSON.parse(localStorage.userData)
    // const { empresa_id: empresaId } = userData
    const getRevendas = () => {
      axios
        .get('api/revenda')
        .then(res => {
          const items = res.data.dados.map(item => ({
            label: item.nome,
            id: item.id,
          }))

          commit('SET_FILTRO_OPTIONS', { name: 'revenda', options: items })
        })
    }

    const getMarcas = () => {
      axios
        .get('api/marca')
        .then(res => {
          const items = res.data.dados.map(item => ({
            label: item.nome,
            id: item.id,
          }))

          commit('SET_FILTRO_OPTIONS', { name: 'marca', options: items })
        })
    }

    const getLinhas = () => {
      axios
        .get('api/linha')
        .then(res => {
          const items = res.data.dados.map(item => ({
            label: item.nome,
            id: item.id,
          }))

          commit('SET_FILTRO_OPTIONS', { name: 'linha', options: items })
        })
    }

    const getStatusOs = () => {
      axios
        .get('api/ordem_servico/listar/status_os')
        .then(res => {
          const items = res.data.dados.map(item => ({
            label: item.nome,
            id: item.id,
          }))

          commit('SET_FILTRO_OPTIONS', { name: 'statusAtendimento', options: items })
        })
    }

    const getRegiao = () => {
      axios
        .get('api/localidade/regiao')
        .then(res => {
          const items = res.data.dados.map(item => ({
            label: item.nome,
            id: item.id,
          }))
          commit('SET_FILTRO_OPTIONS', { name: 'regiao', options: items })
        })
    }

    const getEstado = () => {
      axios
        .get('api/localidade/estado')
        .then(res => {
          const items = res.data.dados.map(item => ({
            label: item.nome,
            id: item.id,
          }))

          commit('SET_FILTRO_OPTIONS', { name: 'estado', options: items })
        })
    }

    getEstado()
    getRegiao()
    getStatusOs()
    getRevendas()
    getMarcas()
    getLinhas()
  },

  cidadeEstado() {
    const { selected } = store.state.seguradora.estado
    const estadoId = selected.map(item => item.id)

    store.dispatch('seguradora/getCidade', estadoId)
  },

  getCidade({ commit }, id) {
    if (id) {
      axios
        .get(`api/localidade/cidade/${id}`)
        .then(res => {
          const items = res.data.dados.map(item => ({
            label: item.nome,
            id: item.id,
          }))

          commit('SET_FILTRO_OPTIONS', { name: 'cidade', options: items })
        })
    }
  },

  setFiltro({ commit }) {
    const { revenda, linha, marca, statusAtendimento, tipoAtendimento, regiao, estado, cidade, abertura } = store.state.seguradora

    const body = {
      filtros: {},
    }

    if (abertura.dataInicial && abertura.dataFinal) {
      body.filtros.abertura = {
        data_inicial: `${abertura.dataInicial} 00:00:00`,
        data_final: `${abertura.dataFinal} 23:59:59`,
      }
    }

    if (revenda.selected.length) {
      const revendas = revenda.selected.map(item => item.id)
      body.filtros.revenda = revendas
    }

    if (linha.selected.length) {
      const linhas = linha.selected.map(item => item.id)
      body.filtros.linha = linhas
    }

    if (marca.selected.length) {
      const marcas = marca.selected.map(item => item.id)
      body.filtros.marca = marcas
    }

    if (tipoAtendimento.selected.length) {
      body.filtros.tipoAtendimento = tipoAtendimento.selected
    }

    if (statusAtendimento.selected.length) {
      const atendimentos = statusAtendimento.selected.map(item => item.id)
      body.filtros.statusAtendimento = atendimentos
    }

    if (regiao.selected.length) {
      const regioes = regiao.selected.map(item => item.id)
      body.filtros.regiao = regioes
    }

    if (estado.selected.length) {
      const estadoFilter = estado.selected.map(item => item.id)
      body.filtros.estado = estadoFilter
    }

    if (cidade.selected.length) {
      const cidadeFilter = cidade.selected.map(item => item.id)
      body.filtros.cidade = cidadeFilter
    }

    const filtro = Object.keys(body.filtros).map(key => ({
      name: key,
      filtro: body.filtros[key],
    }))

    commit('SET_FILTROS', {
      data: filtro })

    if (Object.keys(filtro).length === 0) {
      commit('RESET_FILTER')
    }

    store.dispatch('seguradora/getData')
  },
}

export default {
  namespaced: true,
  state: stateData,
  mutations,
  getters,
  actions,
}
