/* eslint-disable no-unused-vars */
// import store from '@/store'

import axios from '@/../axios-auth'

// // ATENDIMENTOS COUNT SÓ PARA ATENDENTES
// {
//   subject: 'os',
//   action: 'count',
// },

let roles = [
  // PERMISSSOES DE LAYOUT DO APP
  {
    action: 'read',
    subject: 'Auth',
  },
  // PERMISSÃO DE DASHBOARD
  {
    action: 'read',
    subject: 'dashboard',
  },
  // // VISUALIZAR PÁGINA DE OS
  // {
  //   action: 'read',
  //   subject: 'os',
  // },
  // // VISUALIZAR PAGINA DE RELATORIOS
  // {
  //   subject: 'relatorio',
  //   action: 'read',
  // },
  // {
  //   subject: 'relatorio.atendente',
  //   action: 'read',
  // },
  // // VISUALIZAR PÁGINA DE CONTROLE DE ACESSO
  // {
  //   subject: 'ACL',
  //   action: 'read',
  // },
  // // VISUALIZAR PÁGINA DO DIRECIONADOR
  // {
  //   subject: 'direcionador',
  //   action: 'read',
  // },
  // // ANEXAR ARQUIVO
  {
    subject: 'anexos/arquivos',
    action: 'read',
  },
  // // ASSINAR
  {
    subject: 'assinar',
    action: 'read',
  },
  // // CADASTRAR OS
  // {
  //   subject: 'os',
  //   action: 'store',
  // },

  // // PÁGINA DE ORÇAMENTO NO LOGIN SEGURADORA
  // {
  //   subject: 'seguradora/orcamento',
  //   action: 'read',
  // },
  // // PÁGINA DO AUDITOR
  // {
  //   subject: 'auditor',
  //   action: 'read',
  // },
  // // PÁGINA DE ORÇAMENTO
  // {
  //   subject: 'orcamento',
  //   action: 'read',
  // },
  // // PÁGINA FINANCEIRO
  // {
  //   subject: 'financeiro',
  //   action: 'read',
  // },
  // // PÁGINA PREVENTIVO
  // {
  //   subject: 'preventivo',
  //   action: 'read',
  // },
  // // ADMINISTRADOR, CADASTRO
  // {
  //   subject: 'adm/cadastro',
  //   action: 'read',
  // },

  // // PÁGINA DO TÉCNICO
  // {
  //   subject: 'tecnico',
  //   action: 'read',
  // },
]
function getUserRoles() {
  const userData = JSON.parse(localStorage.getItem('userData'))

  // // LISTAGEM DE EMPRESAS
  // {
  //   subject: 'empresa',
  //   action: 'read',
  // },
  // // // // VISUALIZAR EMPRESA
  // {
  //   subject: 'empresa/view',
  //   action: 'read',
  // },

  if (!userData) return []

  if (!userData.permissoes) return []

  const permissoes = JSON.parse(userData.permissoes)

  if (!permissoes) return []

  const permissoesArray = []

  Object.keys(permissoes).forEach(key => {
    permissoes[key].forEach(item => permissoesArray.push(item))
  })

  return permissoesArray
}

roles = [...roles, ...getUserRoles()]

export const initialAbility = roles || []

export const _ = undefined
