import axios from '@/../axios-auth'
import store from '..'

const stateData = {
  os: null,
  showLoading: false,
  historicoOs: {},
  loadingHistorico: false,
  orcamentosOs: [],
  orcamentoSelected: null,
}
const mutations = {
  SET_OS(state, data) {
    state.os = data
  },

  SET_OS_HISTORICO(state, data) {
    if (data.eventos) data.eventos = data.eventos.reverse()
    state.historicoOs = data
  },

  SET_ORCAMENTOS_OS(state, value) {
    state.orcamentosOs = value
  },

  SET_ORCAMENTO_SELECTED(state, value) {
    state.orcamentoSelected = value
  },

  SET_SHOW_LOADING(state, value) {
    state.showLoading = value
  },

  SET_SHOW_LOADING_HISTORICO(state, value) {
    state.loadingHistorico = value
  },
}
const getters = {}
const actions = {
  // eslint-disable-next-line no-unused-vars
  async getOsData({ commit }, { id }) {
    try {
      commit('SET_SHOW_LOADING', true)

      const { data } = await axios.get(`api/ordem_servico/view/${id}`)
      commit('SET_OS', data.dados[0])

      commit('SET_SHOW_LOADING', false)

      return data.dados[0]
    } catch (error) {
      commit('SET_SHOW_LOADING', false)
      return { error }
    }
  },

  // eslint-disable-next-line no-unused-vars
  async getTimeLine({ commit }, { id }) {
    const formatTimezone = dados => {
      const a = dados.split(' ')
      const b = a[0].split('-')
      const dataBr = `${b[2]}/${b[1]}/${b[0]}`
      return dataBr
    }

    try {
      const { data } = await axios.get(`api/ordem_servico/time_line/${id}`)
      const { os } = store.state.os

      const timeLine = {}

      timeLine.dataAbertura = formatTimezone(os.data_abertura)

      data.dados.forEach(item => {
        const statusId = item.status_os_id

        switch (statusId) {
          case 2:
            timeLine.agendamento = true
            timeLine.dataAgendamento = formatTimezone(item.created_at)
            break
          case 3:
            timeLine.aguardandoAprovacao = true
            timeLine.dataAguardandoAprovacao = formatTimezone(item.created_at)
            break
          case 4:
            timeLine.emReparo = true
            timeLine.dataEmReparo = formatTimezone(item.created_at)
            break
          case 5:
            timeLine.reparoConcluido = true
            timeLine.dataReparoConcluido = formatTimezone(item.created_at)
            break
          case 6:
            timeLine.reparoEntregue = true
            timeLine.dataReparoEntregue = formatTimezone(item.created_at)
            break
          case 7:
            timeLine.negado = true
            timeLine.dataNegado = formatTimezone(item.created_at)
            break
          case 8:
            timeLine.cancelado = true
            timeLine.dataCancelado = formatTimezone(item.created_at)
            break
          case 9:
            timeLine.troca = true
            timeLine.dataTroca = formatTimezone(item.created_at)
            break
          default:
            timeLine.ok = null
        }
      })

      return { timeLine }
    } catch (error) {
      return { error }
    }
  },

  // eslint-disable-next-line no-unused-vars
  async getHistoricoOs({ commit }, { id }) {
    try {
      commit('SET_SHOW_LOADING_HISTORICO', true)
      const { data } = await axios.get(`api/ordem_servico/historico/${id}`)
      commit('SET_OS_HISTORICO', data.dados)
      commit('SET_SHOW_LOADING_HISTORICO', false)

      return { items: data.dados }
    } catch (error) {
      return { error }
    }
  },

  // eslint-disable-next-line no-unused-vars
  async getOrcamentosOs({ commit }, { id }) {
    try {
      const { data } = await axios.get(`api/orcamento/listar/${id}`)
      const items = data.dados
      // this.load = false

      commit('SET_ORCAMENTOS_OS', items)

      return { items }
    } catch (error) {
      return { error }
    }
  },

  // eslint-disable-next-line no-unused-vars
  async aprovarOrcamento({ commit }, body) {
    try {
      const { data } = await axios.post('api/orcamento/seguradora/aprovar', body)
      return data.dados
    } catch (error) {
      return { error }
    }
  },

  // eslint-disable-next-line no-unused-vars
  async negarOrcamento({ commit }, body) {
    try {
      const { data } = await axios.post('api/orcamento/seguradora/negar', body)
      return data.dados
    } catch (error) {
      return { error }
    }
  },
}

export const os = {
  namespaced: true,
  state: stateData,
  mutations,
  getters,
  actions,
}
