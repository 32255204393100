import Vue from 'vue'
import Vuex from 'vuex'
// Modules
import app from './app'
import appConfig from './app-config'
import auth from './auth'
import verticalMenu from './vertical-menu'
import selectedMenu from './selectedmenu'
import orcamento from './orcamento'
import direcionador from './direcionador'
import acl from './acl'
import seguradora from './seguradora'
import { os } from './os'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    selectedMenu,
    orcamento,
    direcionador,
    acl,
    seguradora,
    os,
  },
  strict: process.env.DEV,
})
