import axios from '../../../axios-auth'

const state = {
  items: [],
  filteredList: [
  ],
}
const mutations = {
  SET_ITEMS(stateObj, items) {
    stateObj.items = items
  },
  SET_FILTERED_ITEMS(stateObj, items) {
    stateObj.filteredList = items
  },
}

const getters = {
  indicatorsData(stateObj) {
    const { filteredList } = stateObj

    let qtOSFinalizada = 0
    let somaQDA = 0

    let valorTotalSeguradora = 0
    let qtS6 = 0

    let qtS9 = 0
    let valorTotalSeguradora9 = 0

    let qtReclamacao = 0
    let somaIS = 0

    // [reparabilidade, troca, reincidência]
    const reparoEntregueArray = [0, 0, 0]

    filteredList.forEach(o => {
      if (o.status_os_id === 6 || o.status_os_id === 7 || o.status_os_id === 9) {
        qtOSFinalizada += 1
        somaQDA += o.dias_em_aberto

        if (o.produto_valor) {
          somaIS += parseFloat(o.produto_valor)
        }
      }

      if (o.status_os_id === 6) {
        qtS6 += 1
        reparoEntregueArray[0] += 1
        if (o.valor_total_seguradora) {
          valorTotalSeguradora += parseFloat(o.valor_total_seguradora)
        }
      }

      if (o.status_os_id === 9) {
        reparoEntregueArray[1] += 1
        qtS9 += 1
        if (o.valor_total_seguradora) {
          valorTotalSeguradora9 += parseFloat(o.valor_total_seguradora)
        }
      }
      if (o.status_os_id === 13) {
        reparoEntregueArray[2] += 1
      }

      if (o.reclamacao === 1) {
        qtReclamacao += 1
      }
    })

    // RETURN [reparabilidade, troca, reincidência]
    const calcReparoEntregue = () => {
      const array = reparoEntregueArray
      const sum = array.reduce((a, b) => a + b, 0)
      if (sum === 0) {
        return [0, 0, 0]
      }
      // eslint-disable-next-line no-mixed-operators
      const percentual = array.map(value => (value * 100 / sum).toFixed(2))

      return percentual
    }
    const [REP, PT, REIN] = calcReparoEntregue()

    const tCMG = parseInt(qtS6) + parseInt(qtS9)
    const vCMG = parseFloat(valorTotalSeguradora) + parseFloat(valorTotalSeguradora9)

    const CMR = ((valorTotalSeguradora / qtS6) || 0).toFixed(2)
    const TAT = ((somaQDA / qtOSFinalizada) || 0).toFixed(0)
    const CMG = ((vCMG / tCMG) || 0).toFixed(2)
    const REC = (((qtReclamacao / filteredList.length) * 100) || 0).toFixed(2)

    const LMI = (((CMG / somaIS) * 100) || 0).toFixed(2)

    return {
      cmr: `${Number.isNaN(CMR) ? 0 : CMR}`,
      cmg: `${Number.isNaN(CMG) ? 0 : CMG}`,
      tat: `${Number.isNaN(TAT) ? 0 : TAT}`,
      pt: `${Number.isNaN(PT) ? 0 : PT}`,
      rep: `${Number.isNaN(REP) ? 0 : REP}`,
      rein: `${Number.isNaN(REIN) ? 0 : REIN}`,
      rec: `${Number.isNaN(CMR) ? 0 : REC}`,
      lmi: `${Number.isNaN(CMR) ? 0 : LMI}`,
    }
  },
}

const actions = {
  getListOrcamentos({ commit }, { modulo, query }) {
    commit('SET_ITEMS', [])
    return new Promise((resolve, reject) => {
      const empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
      axios
        .get(`api/orcamento/listar/empresa/${empresaID}`, { params: { modulo, ...query } })
        .then(res => {
          if (modulo === 'orcamento') commit('SET_ITEMS', res.data.dados.filter(item => item.servicos.some(servico => servico.tipo_servico !== 'VISITA')))
          if (modulo === 'financeiro') commit('SET_ITEMS', res.data.dados.filter(item => item.status === 'APROVADO - AGUARDANDO PAGAMENTO' || item.status === 'APROVADO - PAGO' || item.status === 'APROVADO - PAGO PARCIAL'))
          commit('SET_ITEMS', res.data.dados)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
