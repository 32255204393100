import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'

import {
  admin,
} from './routes/index'

let routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/anexar/arquivos/:id',
    name: 'anexar.arquivos',
    component: () => import('@/views/AnexarArquivos.vue'),
    meta: {
      layout: 'full',
    },
    props: route => ({ id: route.params.id, hash: route.hash }),
  },
  {
    path: '/assinar/:id',
    name: 'assinar',
    component: () => import('@/views/Assinatura.vue'),
    meta: {
      layout: 'full',
    },
    props: route => ({ id: route.params.id, hash: route.hash }),
  },
]
Vue.use(VueRouter)
// const token = localStorage.getItem('token')
routes = admin

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})
if (!localStorage.userData) {
  localStorage.removeItem('token')
  router.replace('/login')
}

router.beforeEach((to, _, next) => {
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    next({ name: 'misc-not-authorized' })
    return null
  }

  return next()
})

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
